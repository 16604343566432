import React from 'react'
import Container from '@components/shared/container'
import Spacer from '@shared/Spacer'
import ServiceCard from './ServiceCard'

const ServiceSection = props => {
  return (
    <section className={props.option}>
      <Container>
        { props.option === 'services__small' && <div className="services__title">Et aussi<Spacer type="small"/></div> }
        <ul className="services__list">
          {props.data.map(({ node }) =>
            <li key={node.serviceId}>
              <ServiceCard data={node} />
            </li>
          )}
        </ul>
      </Container>
    </section>
  )
}

export default ServiceSection
