const seoData = {
  "openGraph": {
    "id": "services",
    "title": "Nos services",
    "description": "Liste de services que nous propsons.",
  },
  "microdata": {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Nos services",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": "https://www.acolad.fr/",
          "name": "Accueil"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": "https://www.acolad.fr/services",
          "name": "Nos services"
        }
      ]
    }
  }
}

export default seoData
