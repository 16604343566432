import React from 'react'

const Service = props => {
  return (
    <article className="card card--service" id={props.data.slug}>
      <h2 className="title">{props.data.title}</h2>
      <p className="description">{props.data.content}</p>
    </article>
  )
}

export default Service
