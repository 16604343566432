import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/layout/layout'
import SEO from '@components/shared/seo'
import Container from '@components/shared/container'
import ServiceSection from '@components/pages/services/ServicesSection'
import searchIcon from '@images/search_noir.svg'
import seoMeta from '@seo-data/services'

export default class ServicesPage extends React.Component {
  constructor ({ data }) {
    super()
    const bigServices = data.services.edges.filter(item => item.node.category === 'big')
    const smallServices = data.services.edges.filter(item => item.node.category === 'small')
    this.state = {
      allServices: data.services.edges,
      bigServices: bigServices,
      smallServices: smallServices,
      filteredServices: data.services.edges,
      filterActivated: false,
      filter: ''
    }
  }
  handleFilter (inputString) {
    inputString ? this.setState({ filterActivated: true }) : this.setState({ filterActivated: false })
    const result = this.state.allServices.filter(element => {
      const searchedText = (element.node.title).toLowerCase()
      return searchedText.includes(inputString)
    })
    this.setState({ filteredServices: result, filter: inputString })
  }

  render () {
    let servicesContent
    if (this.state.filteredServices.length < 1) {
      servicesContent =
      <div className="services__content">
        <p className="services__noresult"> Désolé...<br />Aucun résultat ne correspond à votre recherche</p>
        <button className="button" onClick={e => { this.handleFilter('') }}>
          Tous les services
        </button>
      </div>
    } else if (this.state.filterActivated) {
      servicesContent =
      <div className="services__content">
        <ServiceSection option="services__filtered" data={this.state.filteredServices}/>
        <button className="button" onClick={e => { this.handleFilter('') }}>
          Tous les services
        </button>
      </div>
    } else {
      servicesContent =
      <div className="services__content">
        <ServiceSection option="services__big" data={this.state.bigServices}/>
        <ServiceSection option="services__small" data={this.state.smallServices}/>
      </div>
    }
    return (
      <Layout>
        <SEO data={seoMeta} />
        <div className="services page-padding">
          <div className="services__filter">
            <Container>
              <label><img src={searchIcon} alt="rechercher"/>
                <input
                  type="text"
                  placeholder="Rechercher un service"
                  onChange={e => { this.handleFilter(e.target.value.toLowerCase().trim()) }}
                  value={ this.state.filter }
                />
              </label>
            </Container>
          </div>
          {servicesContent}
        </div>
      </Layout>
    )
  }
}

export const query = graphql` 
query queryServices {
  services : allServicesJson {
    edges {
      node {
        serviceId
        slug
        category
        title
        content
        image_path
      }
    }
 }
 images : allFile(filter: {relativePath: {regex: "/service/"}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
